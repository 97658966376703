import React from "react"
import Hero from "../components/hero"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MiniPage from "../components/minipage"

const description =
  "Pomegenix Solutions is a team of designers, developers, and engineers that builds experiences that people love."

const text = `We’ll work with you to understand your business goals and aspirations, so we can deliver a roadmap detailing exactly how you can get there. We’ll tailor the amount and level of consultancy to your needs. So, whether you’re an SME looking for initial consultation and project delivery, or an IT manager with an in-house team, looking for further expertise and resource or guidance at a tactical level, we can help.

From ad-hoc, helpful advice to a fully managed design and implementation, we’re happy to provide as much or as little help as you need, with our tailored IT consulting services. Speak to one of our highly experienced consultants today, who have a wealth of both business and technical expertise.`

const agile = `An agile business is an organisation that embraces the agile philosophy and values at its core, from its people and culture, to its structure and technology. Consequently, an agile business is customer centric.

An agile business can respond quickly and effectively to opportunities and threats found in its internal and external environments (be they commercial, legal, technological, social, moral or political).`

const business =`Business improvement encompasses many theories and practices, for example business process improvement is a systematic approach to help an organisation optimise it’s underlying processes and procedures to achieve more efficient results. One approach to this is focused improvement, which is primarily about elevating the performance of any system, especially a business system, by working on eliminating its constraints. Performance improvement however focuses on measuring the output of a particular business process or activity, then morphing or manipulating – however slightly - the process to increase the output, efficiency or the effectiveness of said process, activity or procedure. Quality improvement on the other hand is one of the four aspects of the concept of quality management which is a profession in its own right.`
const proddesign =`Design thinking is a human-centered approach to innovation that draws from the designer’s toolkit to integrate the needs of people, the possibilities of technology and the requirements for business success.
Good designers have always applied design thinking to product design (whether physical or digital) because it’s focused on end-to-end product development, and not just the “design phase” part.`
const Services = () => {
  return (
    <>
      <Layout>
        <Hero text="Services" info="Business, Support, Growth" />
        <SEO
          title="A heart of Business with a soul of Consulting"
          description={description}
        />
        <MiniPage title="Our Services" text={text} />
        <MiniPage title="Agile" text={agile} />
        <MiniPage title="Business Improvement" text={business} />
        <MiniPage title="Product Design" text={proddesign} />
      </Layout>
    </>
  )
}

export default Services
